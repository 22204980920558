<template>
    <div>

    </div>
</template>
<style>

</style>
<script>

    import AuthService from '../../services/auth.service';

    export default {
        components: {},
        data() {
            return {}
        },
        beforeRouteEnter(to, from, next) {
            if (to.query.ghost) {
                AuthService.quickLogout();
                AuthService.silentLogin(JSON.parse(to.query.ghost));
                next({name: 'Dashboard'});
            } else {
                next();
            }
        }
    }
</script>
